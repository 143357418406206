import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const AddListSectionModal = Loadable(() => import("./AddListSectionModal"));

function AddListSectionModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const AddListSectionModalContainer = (props) => {
  return (
    <AddListSectionModal
      {...props}
      fallback={<AddListSectionModalAsyncLoading />}
    />
  );
};

export default AddListSectionModalContainer;
