import Loadable from "@loadable/component";
const EpisodeTranscriptPrompt = Loadable(() =>
  import("./EpisodeTranscriptPrompt")
);

function EpisodeTranscriptAsyncLoading() {
  return null;
}

const EpisodeTranscriptPromptContainer = (props) => {
  return (
    <EpisodeTranscriptPrompt
      {...props}
      fallback={<EpisodeTranscriptAsyncLoading />}
    />
  );
};

export default EpisodeTranscriptPromptContainer;
