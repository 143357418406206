import { createSelector } from "reselect";

export const selectUsers = (state) => state.user.getIn(["users", "entities"]);

export const selectUserIdFromUsername = (state, id) =>
  state.user.getIn(["users", "id_by_username", id?.toString().toLowerCase()]);
const getId = (state, id, by_username) =>
  (by_username ? selectUserIdFromUsername(state, id) : id) || "";

export const selectSpecificUser = (state, id, by_username = false) => {
  if (id) {
    return state.user.getIn([
      "users",
      "entities",
      getId(state, id, by_username)?.toString(),
    ]);
  }

  return;
};
export const selectUserLoading = (state, id = "") =>
  state.user.getIn(["users", "loading", id?.toString()]) || false;
// this will always be by the ID we asked for, either actual ID or username
export const selectUserFailed = (state, id = "") =>
  state.user.getIn(["users", "failed", id?.toString()]) || false;

export const selectLoggedInStatus = (state) => state.user.get("logged_in");

const selectUserEpisodeToggleStatusMemoized = createSelector(
  [selectUsers, (_, userId) => userId],
  (users, userId) =>
    users.getIn([
      userId?.toString(),
      "settings",
      "include_transcripts_in_search",
    ]) || false
);

export const makeSelectUserEpisodeToggleStatus = () =>
  selectUserEpisodeToggleStatusMemoized;
