import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const EditListSectionModal = Loadable(() => import("./EditListSectionModal"));

function EditListSectionModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const EditListSectionModalContainer = (props) => {
  return (
    <EditListSectionModal
      {...props}
      fallback={<EditListSectionModalAsyncLoading />}
    />
  );
};

export default EditListSectionModalContainer;
