import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const DISCOVER_UserList_LOADING_STYLES = {
  height: {
    height: "500rem",
    maxHeight: "500rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "337rem",
      maxHeight: "337rem",
    },
  },
};

const UserList = Loadable(() => import("./UserListFullWidth"));

function UserListAsyncLoading(props) {
  return (
    <LoadingOverlay
      height={"136rem"}
      styles={DISCOVER_UserList_LOADING_STYLES}
    />
  );
}

const UserListContainer = (props) => {
  return <UserList {...props} fallback={<UserListAsyncLoading />} />;
};

export default UserListContainer;
