import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import {
  CREATOR_EDIT_MODULE,
  CREATOR_VIEW_MODULE,
  USERLIST_COMMON_MODULE,
  USER_PROFILE_MODULE,
} from "modules/moduleIds";
import { Switch } from "react-router-dom";

import RegisteredRoute from "components/Common/RegisteredRoute";
import RegistrationRequired from "pages/Auth/RegistrationRequired";
import TopLevelRoute from "pages/Common/TopLevelRoute";

import CreatorEdit from "./Creators/CreatorEdit/async";
import CreatorWizard from "./Creators/CreatorEdit/CreatorWizardAsync";
import CreatorView from "./CreatorView/async";
import Creators from "./Discover/Creators/async";

import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const creatorSkeletonStyles = {
  topHeaderPlaceholder: {
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      minHeight: "6.5rem",
      position: "relative",
      width: "100vw",
    },

    [createBreakpoint({ min: 1400 })]: {
      minHeight: "4.5rem",
    },
  },
};

function getCreatorRoutes({ isDesktop, topLevelRouteProps }) {
  return (
    <Switch>
      <TopLevelRoute
        path="/creators/edit/:id/:stage?"
        withFooter={isDesktop}
        withHeader={isDesktop}
        styles={creatorSkeletonStyles}
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_EDIT_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <CreatorEdit />
      </TopLevelRoute>

      <TopLevelRoute
        exact
        path="/creators/add"
        withFooter={isDesktop}
        withHeader={isDesktop}
        styles={creatorSkeletonStyles}
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_EDIT_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <CreatorWizard />
      </TopLevelRoute>

      <TopLevelRoute
        path="/creators/:id"
        {...topLevelRouteProps}
        height="250rem"
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USER_PROFILE_MODULE,
        ]}
      >
        <CreatorView />
      </TopLevelRoute>

      <TopLevelRoute
        exact
        path="/creator(s?)/:sort"
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
        ]}
      >
        <Creators />
      </TopLevelRoute>

      <TopLevelRoute
        exact
        path="/creator(s?)"
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
        ]}
      >
        <Creators />
      </TopLevelRoute>
    </Switch>
  );
}

export default getCreatorRoutes;
