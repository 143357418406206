export const ADD_FEED_MODULE = "addFeed";
export const ADMIN_MODULE = "admin";
export const API_ACCESS_MODULE = "apiAccess";
export const APP_MODULE = "app";
export const AUTH_MODULE = "auth";
export const BADGES_MODULE = "badges";
export const BLOG_MODULE = "blog";
export const ALERT_MODULE = "alert";
export const BRANDS_MODULE = "brands";
export const TABLE_MODULE = "table";
export const CHART_MODULE = "chart";
export const CATEGORIES_LIST_MODULE = "categoriesList";
export const CATEGORY_MODULE = "category";
export const CLAIMING_MODULE = "claiming";
export const CREATOR_EDIT_MODULE = "creatorEdit";
export const CREATORS_LIST_MODULE = "creatorsList";
export const CREATOR_VIEW_MODULE = "creatorView";
export const CREDITS_LIST_MODULE = "creditsList";
export const EPISODES_LIST_MODULE = "episodesList";
export const EXPORTS_MODULE = "exports";
export const FEEDBACK_MODULE = "feedback";
export const INTEGRATION_MODULE = "integration";
export const INTEGRATIONS_LIST_MODULE = "integrationsList";
export const MAGIC_LINK_MODULE = "magicLink";
export const MERCH_MODULE = "merch";
export const MERCH_LIST_MODULE = "merchList";
export const MODALS_MODULE = "modals";
export const PAGINATION_MODULE = "pagination";
export const PLAYER_MODULE = "player";
export const PODCAST_MODULE = "podcast";
export const PODCASTS_LIST_MODULE = "podcastsList";
export const CONTACTS_LIST_MODULE = "contactsList";
export const POSTS_LIST_MODULE = "postsList";
export const PROFILE_MODULE = "profile";
export const RATING_MODULE = "rating";
export const REVIEW_MODULE = "review";
export const REVIEWS_LIST_MODULE = "reviewsList";
export const SEARCH_MODULE = "search";
export const SAVE_SEARCHES_MODULE = "saveSearches";
export const SIMPLE_FORMS_MODULE = "simpleForms";
export const SUGGESTED_CREDITS_LIST_MODULE = "suggestedCreditsList";
export const TAG_MODULE = "tag";
export const TIMELINE_MODULE = "timeline";
export const USER_MODULE = "user";
export const USERLIST_MODULE = "userlist";
export const USERLIST_COMMON_MODULE = "userlistCommon";
export const USERLISTS_LIST_MODULE = "userlistsList";
export const USER_PROFILE_MODULE = "userProfile";
export const USER_REQUESTS_LIST_MODULE = "userRequestsList";
export const USERS_LIST_MODULE = "usersList";
export const VANITY_URL_MODULE = "vanityUrl";
export const ADVERTISE_MODULE = "advertise";
export const NETWORK_MODULE = "networks";
export const CONTACT_MODULE = "contacts";
export const INSIGHTS_PDF_MODULE = "insights";
