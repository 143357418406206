import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

import ScreenSizes from "styles/ScreenSizes";

const Networks = Loadable(() => import("./NetworksFullWidth"));

const DISCOVER_NETWORK_LOADING_STYLES = {
  height: {
    height: "500rem",
    maxHeight: "500rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "337rem",
      maxHeight: "337rem",
    },
  },
};

function NetworksAsyncLoading(props) {
  return (
    <LoadableLoading
      key="topLevelRouteLoadingOverlay"
      withPageHeader
      height={"136rem"}
      styles={DISCOVER_NETWORK_LOADING_STYLES}
    />
  );
}

const NetworksContainer = (props) => {
  return <Networks {...props} fallback={<NetworksAsyncLoading />} />;
};

export default NetworksContainer;
