import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const AddListEntityModal = Loadable(() => import("./AddListEntityModal"));

function AddListEntityModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const AddListEntityModalContainer = (props) => {
  return (
    <AddListEntityModal
      {...props}
      fallback={<AddListEntityModalAsyncLoading />}
    />
  );
};

export default AddListEntityModalContainer;
