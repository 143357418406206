import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UserlistDetailEditModal = Loadable(() =>
  import("./UserlistDetailEditModal")
);

function UserlistDetailEditModalAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UserlistDetailEditModalContainer = (props) => {
  return (
    <UserlistDetailEditModal
      {...props}
      fallback={<UserlistDetailEditModalAsyncLoading />}
    />
  );
};

export default UserlistDetailEditModalContainer;
